<template>
  <div class="corpuses" id="select-corpus">
    <Select2 @select="select($event)" :settings="selectConfig" ref="select2" tabindex="0" />
  </div>
</template>

<script>
  import Select2 from 'v-select2-component'
  import config from '@/config.js'

  export default {
    name: 'CorpusSelect',
    props: ['exclude', 'corpus'],
    components: {
      Select2
    },
    mounted() {
      if (this.corpus){
        this.$refs.select2.select2.append(
          `<option selected value="${this.corpus.id}">${this.corpus.display}</option>`
        ).trigger('change')
      }
    },
    computed: {
      selectConfig(){
        return {
          placeholder: "Select a corpus",
          allowClear: true,
          width: '100%',
          dropdownParent: "#select-corpus",
          ajax: {
            delay: 250,
            url: config.apiUrl + '/corpuses/autocomplete',
            headers: config.apiHeaders,
            data: (params) => {
              return {
                query: params.term
              }
            },
            processResults: (data) => {
              return {
                results: data.map(corpus => {
                  return {
                    id: corpus.id,
                    text: corpus.display,
                    corpus: corpus
                  }
                })
              }
            }
          }
        }
      }
    },
    methods: {
      select(event){
        this.$emit('select', event.corpus)
      }
    }
  }
</script>
