<template>
  <div>
    <table class="table w-100" :id="getTableId()">
      <thead>
        <tr v-if="searchableByColumn" :class="showFilterTr ? '' : 'd-none'">
          <th
            v-for="(column, index) in columns"
            :key="'th-filter-' + index"
            :class="column.thClass"
          >
            <span v-if="column.sortComp">
              <component
                v-bind:is="column.sortComp"
                @update="setFilter(index, $event)"
              ></component>
            </span>
            <input
              v-else-if="column.searchable"
              v-model="filters[index]"
              type="text"
              class="form-control form-control-sm"
              :placeholder="column.title"
              :key="filterIndex"
            />
            <span v-if="index == columns.length - 1">
              <b-icon
                icon="trash2"
                id="removeFilterIcon"
                aria-hidden="true"
                class="pointer"
                @click="removeFilters"
                title="Close and remove all filters"
              ></b-icon>
              <b-tooltip target="removeFilterIcon" placement="bottom"
                >Remove all filters</b-tooltip
              >
            </span>
          </th>
        </tr>
        <tr>
          <th
            v-for="(column, index) in columns"
            :key="'th-' + index"
            :class="column.thClass"
          >
            {{ column.title }}
            <span v-if="index == columns.length - 1 && showFilterTr == false">
              <b-icon
                icon="filter-circle"
                id="openFilterIcon"
                aria-hidden="true"
                class="pointer"
                @click="openFilters"
                title="Filters"
              ></b-icon>
              <b-tooltip target="openFilterIcon" placement="bottom"
                >Show column filters</b-tooltip
              >
            </span>
          </th>
        </tr>
      </thead>
      <tbody></tbody>
    </table>
  </div>
</template>

<style scoped>
* >>> table tbody tr {
  cursor: pointer;
}
* >>> table tbody tr.selected td {
  background-color: rgb(4 123 254 / 8%);
}
</style>

<script>
import Vue from "vue";
import router from "@/router/index.js";
import Utils from '@/utils.js'
import config from "@/config.js";
import $ from "jquery";
window.$ = $;

import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.css";
import '@/utilities/datatables-pagination.js'
import '@/utilities/datatables-pagination.css'

export default {
  name: "LegamDataTables",
  props: [
    "columns",
    "url",
    "JSONData",
    "selected",
    "searchableByColumn",
    "trFunction",
    "additionalData",
    "additionalSettings",
  ],
  data() {
    return {
      selectedRows: this.selected,
      table: null,
      dtAdditionalSettings: this.additionalSettings ? this.additionalSettings : {},
      timer: null,
      timerPage: null,
      showFilterTr: true,
      filters: Array(this.columns.length).fill(""),
      filterIndex: 0,
      tableId: null,
    };
  },
  watch: {
    selected() {
      this.selectedRows = this.selected;
      // TODO: fails if row is "No matching records found"
      let selectedIds = this.selected.map((row) => row.id);
      $("tr.selected").each((index, tr) => {
        if (!selectedIds.includes($(tr).data("id"))) {
          $(tr).removeClass("selected");
        }
      });
    },
    filters() {
      this.filterDataTablesData();
    },
  },
  methods: {
    setTableId(){
      if (this.tableId == null){
        this.tableId =  Utils.uuidv4()
        if (this.url){
          this.tableId = "dt-" + this.url.replace(/[/:/]/g, "");
        }
      }
    },
    getTableId(){
      if (this.tableId == null){
        this.setTableId()
      }
      return this.tableId
    },
    filterDataTablesData() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.filters.forEach((filter, index) => {
          if (this.table.column(index).search() !== filter) {
            this.table.column(index).search(filter);
          }
        });
        this.table.draw();
      }, 500);
    },
    reloadTable() {
      this.table.draw();
    },
    removeFilters() {
      // this.showFilterTr = false
      this.filters = Array(this.columns.length).fill("");
    },
    openFilters() {
      this.showFilterTr = true;
    },
    setFilter(index, value) {
      this.filters[index] = value;
      this.filterDataTablesData();
    },
  },
  mounted() {
    let that = this;

    // TODO: programally generated component won't fire event, so we made it with jQuery
    $(document).on("buttonClicked", function (event, dataId) {
      that.$emit("click", dataId);
    });

    this.table = $(`table#${this.getTableId()}`).DataTable({
      processing: false,
      pageLength: 100,
      ...this.dtAdditionalSettings,
      stateSave: true,
      lengthMenu: [25, 50, 100, 200],
      pagingType: "input",
      language: {
        info: "_START_-_END_ of _TOTAL_",
        infoFiltered: " - filtered from _MAX_",
      },
      dom: "rlt<'row'<'col-sm-12 col-md-4'i><'col-sm-12 col-md-8'p>>",
      ...(
        this.url &&
        {
          ajax: {
            url: config.apiUrl + this.url,
            headers: config.apiHeaders,
            data: function (data) {
              data.additionalData = that.additionalData;
            },
          },
          serverSide: true,
          stateSaveCallback: function(settings, data) {
            localStorage.setItem('DataTables_' + settings.sInstance, JSON.stringify(data))
          },
          stateLoadCallback: (settings) => {
            let data = JSON.parse(localStorage.getItem('DataTables_' + settings.sInstance))
            if (data && data.columns){
              data.columns.forEach((column, index) => {
                this.filters[index] = column.search.search
              })
            }
            this.filterIndex++
            return data
          },
        }
      ),
      ...(
        this.JSONData &&
        { data: this.JSONData, serverSide: false }
      ),
      createdRow: function (row, data) {
        $(row).attr("data-id", data.id);
        // if (data.articleId == null){
        //   $(row).addClass('selectable')
        // }
        if (that.selectedRows) {
          let selectedIds = that.selectedRows.map((_row) => _row.id);
          if (selectedIds.includes(data.id)) {
            $(row).addClass("selected");
          }
        }
        if (that.trFunction) {
          that.trFunction(row, data);
        }
      },
      columns: this.columns.map((column) => {
        return {
          data: column.field,
          defaultContent: "",
          orderable: column.sortable === true ? true : false,
          createdCell(td, cellData, row) {
            let value = cellData;

            if (column.transformData) {
              value = column.transformData(row);
            }

            if (column.tdClass) {
              $(td).addClass(column.tdClass)
            }

            if (column.tdComp) {
              let componentClass = Vue.extend(column.tdComp);
              let instance = new componentClass({
                propsData: {
                  value: value,
                },
                router,
                store: that.$store,
              });
              instance.$mount();
              $(td).empty();
              td.appendChild(instance.$el);
            } else if (column.noHTML == true) {
              td.innerText = value;
            } else {
              td.innerHTML = value;
            }
          },
        };
      }),
    });

    this.columns.forEach((column, index) => {
      if (column.filterValue){
        this.setFilter(index, column.filterValue)
      }
    })

    $("table tbody").on("click", "tr", function (el) {
      var data = that.table.row(this).data();
      if (that.selectedRows != undefined && !$(el.target.closest('td')).hasClass('no-select')){
      // if (that.selectedRows != undefined) {
        if ($(el.currentTarget).hasClass("selected")) {
          that.selectedRows = that.selectedRows.filter((x) => x.id != data.id);
        } else {
          that.selectedRows.push(data);
        }
        $(el.currentTarget).toggleClass("selected");
      }
      that.$emit("selectChanged", that.selectedRows);
    });
  },
};
</script>
