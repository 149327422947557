<template>
  <div>
    <table class="table w-100">
      <thead>
        <tr v-if="searchableByColumn" :class="showFilterTr ? '' : 'd-none'">
          <th
            v-for="(column, index) in columnsX"
            :key="'th-filter-' + index"
            :class="column.thClass"
          >
            <span v-if="column.sortComp">
              <component
                ref="filterButton"
                @update="setFilter(index, $event)"
                v-bind:is="column.sortComp"
              ></component>
            </span>
            <input
              v-else-if="column.searchable"
              v-model="filters[column.field]"
              type="text"
              class="form-control form-control-sm table-filter"
              :placeholder="column.title"
            />
            <input
              v-else-if="!column.searchable"
              v-model="filters[column.field]"
              class="totally-hidden table-filter"
              type="text"
            />
            <!-- <span v-if="index == columnsX.length - 1">
              <b-icon
                icon="trash2"
                id="removeFilterIcon"
                aria-hidden="true"
                class="pointer"
                @click="removeFilters"
                title="Close and remove all filters"
              ></b-icon>
              <b-tooltip target="removeFilterIcon" placement="bottom"
                >Remove all filters</b-tooltip
              >
            </span> -->
          </th>
        </tr>
        <tr>
          <th
            v-for="(column, index) in columnsX"
            :key="'th-' + index"
            :class="column.thClass"
          >
            {{ column.title }}
            <span v-if="index == columnsX.length - 1 && showFilterTr == false">
              <b-icon
                icon="filter-circle"
                id="openFilterIcon"
                aria-hidden="true"
                class="pointer"
                @click="openFilters"
                title="Filters"
              ></b-icon>
              <b-tooltip target="openFilterIcon" placement="bottom"
                >Show column filters</b-tooltip
              >
            </span>
          </th>
        </tr>
      </thead>
      <tbody></tbody>
    </table>
  </div>
</template>

<style scoped>
* >>> table tbody tr {
  cursor: pointer;
  transition: all 0.3s;
}
* >>> table tbody tr:hover {
  background-color: #ebf0f0;
}
* >>> table tbody tr.selected td {
  background-color: rgb(4 123 254 / 8%);
}
* >>> table tbody tr {
  cursor: pointer;
}
* >>> table tbody tr.selected td {
  background-color: rgb(4 123 254 / 8%);
}
* >>> .page-item,
* >>> .paginate_total {
  margin-right: 4px;
}
* >>> .page-item {
  cursor: pointer;
  transition: 0.2s all;
  padding: 6px 8px;
  border: 1px solid #f8f9fa !important;
  background-color: #efefef;
  color: #212529;
  border-radius: 0.2rem;
}
* >>> .page-item:hover {
  -webkit-filter: brightness(70%);
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}
* >>> .page-item.disabled {
  opacity: 0.4;
  cursor: auto;
}
* >>> .page-item.disabled:hover {
  -webkit-filter: brightness(100%);
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}
* >>> .paginate_input {
  width: 50px;
  border-radius: 0.2rem;
  padding: 5px 6px;
  border: 1px solid #e8e8e8;
}
* >>> .totally-hidden {
  display: none;
}
.dataTables_info {
  display: none !important;
}
</style>

<script>
import Vue from "vue";
import router from "@/router/index.js";
import config from "@/config.js";
import $ from "jquery";
import axios from "axios";
axios.defaults.headers.get["Access-Control-Allow-Origin"] = "*";
window.$ = $;

import "datatables.net-bs4"
import "datatables.net-bs4/css/dataTables.bootstrap4.css"

export default {
  name: "LegamDataTablesClientSide",
  props: [
    "columnsPI",
    "columns",
    "url",
    "selected",
    "searchableByColumn",
    "trFunction",
    "additionalData",
    "additionalDataFilter",
  ],
  data() {
    let columnsX = this.columnsPI ? this.columnsPI : this.columns;
    return {
      columnsX: columnsX,
      selectedRows: this.selected,
      typingTimer: null,
      settings: this.$store.state.user.settings,
      doneTypingInterval: 500,
      table: null,
      totalRecords: null,
      perPage: 50,
      currentPage: 1,
      currentData: null,
      timer: null,
      showFilterTr: true,
      filters: {},
      filtersIndices: columnsX.reduce((a, v, i) => ({ ...a, [v.field]: i}), {}),
      partialDataset: null,
      fullDatasetLoaded: false,
    };
  },
  watch: {
    selected() {
      this.selectedRows = this.selected;
      let selectedIds = this.selected.map((row) => row.id);
      $("tr.selected").each((index, tr) => {
        if (!selectedIds.includes($(tr).data("id"))) {
          $(tr).removeClass("selected");
        }
      });
    },
  },
  computed: {
    isDictionary() {
      const choices = [
        "lemma",
        "sense",
        "categor",
        "protospelling",
        "occurrence",
        "reference",
      ];
      for (const choice of choices) {
        if (this.url.includes(choice)) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    async loadTableData(url, small) {
      url = config["apiUrlClientSide"] + url.replace("/datatables", "");
      const smalls = [
        "annotators",
        "authorhists",
        "authors",
        "editions",
        "manuscripts",
        "reviews",
        "studies",
        "texts",
        "writingtypes",
        "categories",
        "documents",
        "lemmas",
        "occurrences-external-container",
        "occurrences-external",
        "occurrences-internal",
        "protospellings",
        "references",
        "senses",
      ];
      let isSmall = ".json";
      let tableType = url.replace(".json", "");
      tableType = tableType.substring(tableType.lastIndexOf("/") + 1);
      const hasSmall = smalls.includes(tableType);
      if (small && hasSmall) {
        isSmall = "-small.json";
        this.partialDataset = true;
        this.fullDatasetLoaded = false;
      } else {
        this.partialDataset = false;
        this.fullDatasetLoaded = true;
      }
      url = url + isSmall;
      let response = await axios.get(url);
      return this.applyHardFilters(response.data);
    },
    convertArraylike(dataset) {
      if (!dataset.length || !Array.isArray(dataset.at(0))) {
        return dataset;
      }
      const fixed = [];
      for (const line of dataset) {
        let fix = {};
        for (let [index, val] of line.entries()) {
          let field = this.$props.columns[index].field.split(".")[0]
          fix[field] = val;
        }
        fixed.push(fix);
      }
      return fixed;
    },
    escapeRegExp(string) {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    },
    setFilterExternal(name, value) {
      this.filters[name] = value;
      this.sortPageFilter();
    },
    setFilter(index, event) {
      const col = this.columnsX[index].field;
      const strings = [];
      for (const s of event) {
        strings.push(this.escapeRegExp(s));
      }
      if (strings.length) {
        this.filters[col] = strings.join("|");
      } else {
        this.filters[col] = "";
      }
      this.sortPageFilter();
    },
    sortPageFilter() {
      if (this.partialDataset === false && !this.fullDatasetLoaded) {
        window.setTimeout(this.sortPageFilter, 100);
      }
      const order = this.table.order();
      const perPage = parseInt(
        $(".custom-select").first().find(":selected").text()
      );
      this.perPage = perPage;
      const currentPage = parseInt($(".paginate_input").first().val());
      const dataset = this.getDataSubset(order, perPage, currentPage);

      this.table.clear();
      this.table.rows.add(dataset);
      this.table.draw();
      this.updatePagination();
    },
    filterData(dataset) {
      const filters = this.filters;
      if (!filters || !Object.keys(filters).length) {
        return [...dataset];
      }
      for (let [field, search] of Object.entries(filters)) {
        if (!isNaN(field) || !search.length || field === "main") {
          continue;
        }
        // this hack should eventually be removed!
        if (field.includes("biblabel.")) {
          field = field.replace("biblabel.", "biblabels.");
        }
        search = new RegExp(search, "i");
        // eslint-disable-next-line no-inner-declarations
        const filt = (line) => {
          if (this.columnsX[this.filtersIndices[field]].searchField) {
            let index = this.columnsX[this.filtersIndices[field]].searchField
            return line[field][index] && search.test(line[field][index]);
          }
          if (!field.includes(".")) {
            return line[field] && search.test(line[field]);
          }
          let pieces = field.split(".");
          let bit = line;
          for (const piece of pieces) {
            bit = bit[piece];
            if (!bit) {
              return false;
            }
            if (Array.isArray(bit)) {
              bit = bit[0];
            }
          }
          return bit && search.test(bit);
        }
        dataset = dataset.filter(filt);
      }
      const main = "main" in filters ? new RegExp(filters["main"], "i") : null;
      if (main) {
        // eslint-disable-next-line no-inner-declarations
        function cellFilt(cell) {
          return main.test(String(cell));
        }
        // eslint-disable-next-line no-inner-declarations
        function mainFilt(line) {
          let combinedLine = []
          fieldSearch.forEach(item => {
            let data = line
            item.forEach(d => {
              data = data[d]
            })
            if (data) {
              combinedLine.push(data)
            }
          })

          let retVal = false;
          fieldSearch.forEach(item => {
            let data = line
            item.forEach(d => {
              data = data[d]
            })
            if (data && cellFilt(data)) {
              retVal = true
            }
          })
          return retVal;
        }

        let fieldSearch = []
        Object.values(this.columnsX).forEach(item => {
          let field = item.field.split(".")
          if (item.searchField) {
            field.push(item.searchField)
          }
          fieldSearch.push(field)
        })

        dataset = dataset.filter(mainFilt);
      }
      return dataset;
    },
    sortData(sorters, dataset) {
      for (const sorter of sorters) {
        const field = this.columnsX[sorter[0]].field;
        const index = this.columnsX[this.filtersIndices[field]].searchField
        const asc = sorter[1] === "asc";
        let first = asc ? -1 : 1;
        let second = asc ? 1 : -1;
        // eslint-disable-next-line no-inner-declarations
        function compare(a, b) {
          a = a[field];
          b = b[field];
          if (index) {
            a = a[index];
            b = b[index];
          }
          if (typeof a === "string" || a instanceof String) {
            a = a
              .normalize("NFD")
              .replace(/\p{Diacritic}/gu, "")
              .replace(/[^a-zA-Z\s0-9]/g, "")
              .trim()
              .toLowerCase();
          }
          if (typeof b === "string" || b instanceof String) {
            b = b
              .normalize("NFD")
              .replace(/\p{Diacritic}/gu, "")
              .replace(/[^a-zA-Z\s0-9]/g, "")
              .trim()
              .toLowerCase();
          }
          if (a < b) {
            return first;
          }
          if (a > b) {
            return second;
          }
          return 0;
        }
        dataset = dataset.sort(compare);
      }
      return dataset;
    },
    getTranslations(name, field) {
      const out = [];
      const trans = this.$store.state.common[name.replace("Suppressed", "")];
      for (const item of trans) {
        if (this.settings[name].includes(item.id)) {
          out.push(item[field]);
        }
      }
      return out;
    },
    findField(data, search) {
      const first = data[0];
      for (const key in first) {
        if (key.toLowerCase().includes(search)) {
          return key;
        }
      }
      return "biblabels";
    },
    applyHardFilters(dataset) {
      let needed = [
        ["biblabels", "label", false],
        ["biblabelsSuppressed", "label", true],
      ];
      if (this.isDictionary) {
        needed = [["dictionaries", "acronym", false]];
      }
      for (const need of needed) {
        let extra = null;
        const field = need[0];
        const target = need[1];
        const reverse = need[2];
        if (this.settings[field]) {
          const acronyms = this.getTranslations(field, target);
          let columnName = this.findField(dataset, "acronym");
          if (columnName === "biblabels") {
            extra = "label";
          }
          if (!acronyms && !reverse) {
            return [];
          } else if (!acronyms && reverse) {
            continue;
          }
          if (columnName) {
            // eslint-disable-next-line no-inner-declarations
            function bibFilter(line) {
              if (!(columnName in line)) {
                return true;
              }
              let cell = line[columnName];
              if (extra && Array.isArray(cell) && cell.length) {
                cell = cell[0][extra];
              } else if (extra && Array.isArray(cell) && !cell.length) {
                return reverse; // or should this be false?
              }
              if (!reverse) {
                return acronyms.includes(cell);
              } else {
                return !acronyms.includes(cell);
              }
            }
            dataset = dataset.filter(bibFilter);
          }
        }
      }
      return dataset;
    },
    getDataSubset(sorters, perPage, currentPage) {
      let shortData = this.currentData;
      shortData = this.convertArraylike(shortData);
      shortData = this.applyHardFilters(shortData);
      if (this.additionalDataFilter) {
        shortData = this.additionalDataFilter(shortData);
      }
      // first we apply filtering if we have any ...
      // get the 'main search' as well
      const mainSearch = $(".dataTables_filter input")[0];
      if (mainSearch && mainSearch.value.trim()) {
        this.filters["main"] = mainSearch.value.trim();
      }
      else {
        this.filters["main"] = ''
      }
      if (this.filters) {
        shortData = this.filterData(shortData);
      }
      this.totalRecords = shortData.length;
      // next we apply sorting if we have it
      if (sorters) {
        shortData = this.sortData(sorters, shortData);
      }
      // finally we get the correct page and slice the data
      const fromHere = currentPage * perPage - perPage;
      const toHere = fromHere + perPage;
      return shortData.slice(fromHere, toHere);
    },
    removeFilters() {
      const buttons = this.$refs.filterButton;
      if (buttons) {
        for (const filt of buttons) {
          if ("genresSelected" in filt) {
            filt.genresSelected = [];
          } else if ("selectedScriptas" in filt) {
            filt.selectedScriptas = [];
          } else if ("selected" in filt) {
            filt.selected = [];
          }
        }
      }
      // this.showFilterTr = false
      this.filters = {};
      $(".paginate_input").first().val(1);
      setTimeout(() => {
        // if we don't delay here, sometimes the filter still exists when we get the data again
        this.sortPageFilter();
      }, 200);
    },
    openFilters() {
      this.showFilterTr = true;
    },
    updatePagination() {
      let currentPage = parseInt($(".paginate_input").first().val());
      currentPage = isNaN(currentPage) ? 1 : currentPage;

      if (currentPage === 1) {
        $(".first").addClass("disabled");
        $(".previous").addClass("disabled");
      } else {
        $(".first").removeClass("disabled");
        $(".previous").removeClass("disabled");
      }

      let perPage = this.perPage;
      perPage = perPage
        ? perPage
        : parseInt($(".custom-select").first().find(":selected").text());
      let lastPage = "calculating...";
      if (this.fullDatasetLoaded) {
        lastPage = Math.ceil(this.totalRecords / perPage);
      }

      if (currentPage === lastPage || !this.fullDatasetLoaded) {
        $(".next").addClass("disabled");
        $(".last").addClass("disabled");
      } else {
        $(".next").removeClass("disabled");
        $(".last").removeClass("disabled");
      }
      // Current page number input value
      $(".paginate_input").val(currentPage);

      $(".paginate_total").html(lastPage);
    },
  },
  async mounted() {
    // const perPage = this.perPage

    let that = this;

    // TODO: programally generated component won't fire event, so we made it with jQuery
    $(document).on("buttonClicked", function (event, dataId) {
      that.$emit("click", dataId);
    });

    const url = this.$props.url.split("?").at(0);
    let dataset = await this.loadTableData(url, true);
    this.totalRecords = dataset.length;
    this.currentData = dataset;
    let shortData = this.currentData.slice(0, this.perPage);
    shortData = this.convertArraylike(shortData);

    $.fn.dataTableExt.oPagination.input_pi = {
      fnInit: function (oSettings, nPaging) {
        var nFirst = document.createElement("span");
        var nPrevious = document.createElement("span");
        var nNext = document.createElement("span");
        var nLast = document.createElement("span");
        var nInput = document.createElement("input");
        var nTotal = document.createElement("span");
        var nInfo = document.createElement("span");

        var language = oSettings.oLanguage.oPaginate;
        var classes = oSettings.oClasses;
        var info = language.info || "_INPUT_ of _TOTAL_";

        nFirst.innerHTML = language.sFirst;
        nPrevious.innerHTML = language.sPrevious;
        nNext.innerHTML = language.sNext;
        nLast.innerHTML = language.sLast;

        nFirst.className = "first " + classes.sPageButton;
        nPrevious.className = "previous " + classes.sPageButton;
        nNext.className = "next " + classes.sPageButton;
        nLast.className = "last " + classes.sPageButton;

        nInput.className = "paginate_input";
        nTotal.className = "paginate_total";

        nInput.type = "text";

        info = info.replace(
          /_INPUT_/g,
          "</span>" + nInput.outerHTML + "<span>"
        );

        // let nPages = Math.ceil(dataset.length / that.perPage)

        info = info.replace(
          /_TOTAL_/g,
          `<span class="paginate_total">calculating...</span>`
        );
        nInfo.innerHTML = "<span>" + info + "</span>";

        if (!this.fullDatasetLoaded) {
          $(".next").addClass("disabled");
          $(".last").addClass("disabled");
        }

        nPaging.appendChild(nFirst);
        nPaging.appendChild(nPrevious);
        $(nInfo)
          .children()
          .each(function (i, n) {
            nPaging.appendChild(n);
          });
        nPaging.appendChild(nNext);
        nPaging.appendChild(nLast);

        $(nFirst).click(function () {
          const pager = $(".paginate_input").first();
          pager.val(1);
          pager.trigger("change");
        });

        $(nPrevious).click(function () {
          const pager = $(".paginate_input").first();
          const currentPage = parseInt(pager.val());
          pager.val(currentPage - 1);
          pager.trigger("change");
        });

        $(nNext).click(function () {
          const pager = $(".paginate_input").first();
          const currentPage = parseInt(pager.val());
          pager.val(currentPage + 1);
          pager.trigger("change");
        });

        $(nLast).click(function () {
          const pager = $(".paginate_input").first();
          const perP = parseInt(
            $(".custom-select").first().find(":selected").text()
          );
          const lastPage = Math.ceil(that.totalRecords / perP);
          pager.val(lastPage);
          pager.trigger("change");
        });

        // Take the brutal approach to cancelling text selection.
        $("span", nPaging).bind("mousedown", function () {
          return false;
        });
        $("span", nPaging).bind("selectstart", function () {
          return false;
        });
      },
      fnUpdate: function () {
        that.updatePagination();
      },
    };

    if (this.perPage > shortData.length && this.partialDataset) {
      shortData = [];
    }

    this.table = $("table").DataTable({
      data: shortData,
      processing: false,
      serverSide: false,
      retrieve: true,
      searching: false,
      deferRender: true,
      pageLength: that.perPage,
      stateSave: true,
      info: false,
      pagingType: "input_pi",
      columns: this.columnsX.map((column) => {
        return {
          data: column.field,
          defaultContent: "",
          orderable: column.sortable === true ? true : false,
          createdCell(td, cellData, row) {
            let value = cellData;

            if ("transformData" in column && column.transformData) {
              value = column.transformData(row);
            }
            if (column.tdClass) {
              $(td).addClass(column.tdClass);
            }
            if (column.tdComp) {
              let componentClass = Vue.extend(column.tdComp);
              let instance = new componentClass({
                propsData: {
                  value: value,
                },
                router,
                store: that.$store,
              });
              instance.$mount();
              $(td).empty();
              td.appendChild(instance.$el);
            } else {
              td.innerHTML = value;
            }
          },
        };
      }),
    });

    // if (dataset && dataset.data) {
    //  for (const datum of dataset.data) {
    //    this.table.row.add(datum)
    //  }
    // this.table.draw()
    // }

    $("table tbody").on("click", "tr", function () {
      that.$emit("click", that.table.row(this).data());
    });

    $(".dataTables_filter input")
      .first()
      .on("keyup", function () {
        clearTimeout(that.typingTimer);
        that.typingTimer = setTimeout(
          that.sortPageFilter,
          that.doneTypingInterval
        );
      });
    $(".dataTables_filter input")
      .first()
      .on("keydown", function () {
        clearTimeout(that.typingTimer);
        $(".paginate_input").first().val(1);
      });

    $(".sorting").on("click", function () {
      that.sortPageFilter();
    });
    $(".paginate_input")
      .first()
      .on("change", function () {
        that.sortPageFilter();
      });
    $(".table-filter").on("keyup", function () {
      clearTimeout(that.typingTimer);
      that.typingTimer = setTimeout(
        that.sortPageFilter,
        that.doneTypingInterval
      );
    });
    $(".table-filter").on("keydown", function () {
      clearTimeout(that.typingTimer);
      $(".paginate_input").first().val(1);
    });
    $(".custom-select")
      .first()
      .on("change", function () {
        that.pageSize = parseInt(
          $(".custom-select").first().find(":selected").text()
        );
        $(".paginate_input").first().val(1);
        that.sortPageFilter();
      });

    let needLoadFull = false;
    if (this.partialDataset === true && this.perPage > this.totalRecords) {
      needLoadFull = true;
    }

    if (this.partialDataset === true) {
      const fullDataset = await this.loadTableData(url, false);
      this.totalRecords = fullDataset.length;
      this.currentData = fullDataset;
      this.fullDatasetLoaded = true;
      this.updatePagination();
      if (needLoadFull) {
        this.sortPageFilter();
      }
    }

    if (this.additionalDataFilter) {
      this.sortPageFilter();
    }
  },
};
</script>
