<template>
  <div class="texts" id="select-text">
    <Select2 @select="select($event)" :settings="selectConfig" ref="select2" tabindex="0" />
  </div>
</template>

<script>
  import Select2 from 'v-select2-component'
  import config from '@/config.js'

  export default {
    name: 'EditionSelect',
    props: ['exclude', 'text', 'allowClear'],
    components: {
      Select2
    },
    mounted() {
      if (this.text){
        this.$refs.select2.select2.append(
          `<option selected value="${this.text.id}">${this.text.sigleLegam}</option>`
        ).trigger('change')
      }
    },
    computed: {
      selectConfig(){
        return {
          placeholder: "Select a text",
          allowClear: this.allowClear == false ? false : true,
          width: '100%',
          dropdownParent: "#select-text",
          ajax: {
            delay: 250,
            url: config.apiUrl + '/texts/autocomplete',
            headers: config.apiHeaders,
            data: (params) => {
              return {
                query: params.term
              }
            },
            processResults: (data) => {
              return {
                results: data.map(text => {
                  return {
                    id: text.id,
                    text: text.sigleLegam,
                    obj: text
                  }
                })
              }
            }
          }
        }
      }
    },
    methods: {
      select(event){
        this.$emit('select', event.obj)
      }
    }
  }
</script>
