<template>
  <div class="clearfix" v-if="tokens.length">
    <LegamDataTables
      v-if="config.editable"
      v-bind="$data"
      @click="showModalEditTokenXML"
      :additionalSettings="additionalSettings"
    />
    <LegamDataTablesClientSide
      v-else
      v-bind="$data"
      @click="showModalEditTokenXML"
      :additionalSettings="additionalSettings"
    />

    <b-modal
      v-if="config.editable"
      id="modal-edit-token-xml"
      title="Edit token XML"
      ok-variant="secondary"
      size="lg"
      @ok="saveToken"
      ok-title="Save"
    >
      <b-form-group
        v-if="editToken"
        id="token-xml-group"
        label="Token XML"
        label-for="token-xml"
      >
        <b-form-input
          id="token-xml"
          v-model="editToken.xml"
          placeholder="Token XML"
        ></b-form-input>
      </b-form-group>
    </b-modal>

    <b-modal
      v-if="config.editable"
      id="modal-split-token-xml"
      title="Split token XML"
      ok-variant="secondary"
      size="lg"
      @ok="saveSplitToken"
      ok-title="Save"
    >
      <div class="text-center split-text" v-if="editToken && config.editable">
        <span
          v-for="(letter, index) in editToken.xml.split('')"
          :key="'tst-' + index"
          @click="(index > -1 && index < (editToken.xml.length - 1)) ? splitIndex = index : splitIndex"
        >{{ letter }}<span class="legam-split-divider" v-if="splitIndex == index">|</span></span>
      </div>
      <div class="text-center">
        keep annotations etc. on part
        <b-form-radio-group v-model="selectedAB">
          <b-form-radio value="a">a</b-form-radio>
          <b-form-radio value="b">b</b-form-radio>
        </b-form-radio-group>
      </div>
    </b-modal>
  </div>
</template>

<style scoped>
  * >>> .tdAction {
    width: 10px !important;
  }

  * >>> .tdToken {
    width: 600px !important;
  }
  .legam-split-divider {
    padding: 0px 5px;
    color: red;
    font-size: 40px;
  }
  .split-text {
    text-align: center;
    margin-bottom: 10px;
    font-size: 35px;
  }
  .text-center {
    text-align: center;
  }
  .text-center span {
    cursor: s-resize;
  }
</style>

<script>
  import tdColUUID from '@/components/tdColUUID.vue'
  import tdColAction from '@/components/tdColAction.vue'
  import LegamDataTables from '@/components/LegamDataTables.vue'
  import LegamDataTablesClientSide from '@/components/LegamDataTablesClientSide.vue'
  import config from '@/config.js'

  import 'datatables.net-bs4'
  import 'datatables.net-bs4/css/dataTables.bootstrap4.css'
  import '@/utilities/datatables-pagination.js'
  import '@/utilities/datatables-pagination.css'

  export default {
    name: 'TokenTable',
    props: ['tokens', 'editMode', 'divisionFilter'],
    data(){
      return {
        config: config,
        editToken: null,
        selectedAB: 'b',
        splitIndex: 0,
        columns: [
          { title: 'ID', field: 'id', tdComp: tdColUUID, sortable: true, searchable: true },
          { title: 'Index', field: 'idx', sortable: true, searchable: true },
          { title: 'Division', field: 'div', sortable: true, searchable: true, filterValue: this.divisionFilter },
          // { title: 'Idx', field: 'tokenIdx', sortable: true, searchable: true },
          { title: 'Token', field: 'formDoc', tdComp: tdColAction, thClass: 'tdToken', sortable: true, searchable: true,
            transformData: function (row){
              return [{
                text: row.formDoc
              },{
                text: 'Split',
                icon: 'align-center',
                class: 'float-right btn-wrning',
                dataId: JSON.stringify({id: row.id, xml: row.formDoc.replace(/"/gi, "\\\""), type: 'split'})
              },{
                text: 'Delete',
                icon: 'trash',
                class: 'float-right btn-danger',
                dataId: JSON.stringify({id: row.id, xml: row.formDoc.replace(/"/gi, "\\\""), type: 'delete'})
              },{
                text: 'Edit',
                icon: 'pencil',
                class: 'float-right',
                dataId: JSON.stringify({id: row.id, xml: row.formDoc.replace(/"/gi, "\\\""), type: 'edit'})
              },{
                text: 'Show in text',
                icon: 'file-earmark-text',
                class: 'float-right',
                dataId: JSON.stringify({data: row, type: 'highlight'})
              }]
            }
          },
          { title: 'Language', field: 'lang', sortable: true, searchable: true },
          { title: 'Lemmas', field: 'lemmas', sortable: true, searchable: true, tdComp: tdColAction,
            transformData: function (row){
              return row.lemmas ? row.lemmas.map(lemmaData => {
                return {
                  text: `${lemmaData[1]}`,
                  icon: 'book',
                  id: lemmaData[0],
                  to: {name: 'Lemma', params: {id: lemmaData[0]}}
                }
              }) : ''
            }
          },
          { title: '-', field: 'id', thClass: 'tdAction',
            transformData: function (){
              return ''
            }
          }
        ],
        JSONData: this.tokens.map(token => { return {
          id: token.id,
          idx: token.idx+1,
          div: token.division,
          form: token.form,
          formDoc: token.formDoc,
          lang: token.tokenLanguage ? token.tokenLanguage.join(", ") : "",
          lemmas: token.lemmas
        }}),
        key: 1,
        searchableByColumn: true,
        additionalSettings: {
          order: [[1, 'asc']]
        },
      }
    },
    methods: {
      saveSplitToken() {
        this.$store.dispatch('splitToken', {
          tokenId: this.editToken.id,
          index: this.splitIndex+1,
          splitOff: this.selectedAB
        }).then((data) => {
          if (data.data.result == "ok") {
            this.$emit('refresh')
            this.$store.dispatch('addNotification', {
              type: 'success',
              text: `Token splitted`
            })
          }
          else {
            this.$store.dispatch('addNotification', {
              type: 'warning',
              text: data.data.message
            })
          }
        })
      },
      showModalEditTokenXML(data){
        let dataObj = JSON.parse(data)

        if (dataObj.type == 'edit'){
          this.editToken = dataObj
          this.$bvModal.show('modal-edit-token-xml')
        }
        else if (dataObj.type == 'split'){
          this.editToken = dataObj
          this.$bvModal.show('modal-split-token-xml')
        }
        else if (dataObj.type == 'delete'){
          this.$bvModal.msgBoxConfirm('Are you sure?')
          .then(value => {
            if (value){
              this.$store.dispatch('deleteToken', dataObj.id).then(() => {
                this.$emit('refresh')
                this.$store.dispatch('addNotification', {
                  type: 'success',
                  text: `Token deleted`
                })
              })
            }
          })
        }
        else if (dataObj.type == 'highlight') {
          this.$emit('highlight', dataObj.data)
        }
      },
      saveToken(){
        this.$store.dispatch('updateToken', {
          'token_id': this.editToken.id,
          'xml': this.editToken.xml
        }).then(data => {
          if (data.data.result == "ok") {
            this.$emit('refresh')
            this.$store.dispatch('addNotification', {
              type: 'success',
              text: `Token updated`
            })
          }
          else {
            this.$store.dispatch('addNotification', {
              type: 'warning',
              text: data.data.message
            })
          }
        })
      }
    },
    components: {
      LegamDataTables,
      LegamDataTablesClientSide
    },
    created() {
      window.addEventListener('keydown', (e) => {
        if (this.editToken && this.editToken.xml){
          if (e.key == 'ArrowLeft') {
            this.splitIndex--
            if (this.splitIndex < 0) {
              this.splitIndex = 0
            }
          }
          else if (e.key == 'ArrowRight') {
            this.splitIndex++
            if (this.splitIndex > (this.editToken.xml.length - 2)) {
              this.splitIndex = this.editToken.xml.length - 2
            }
          }
        }
      });
    },
  }
</script>
