<template>
  <div class="editions" id="select-edition">
    <Select2 @select="select($event)" :settings="selectConfig" ref="select2" tabindex="0" />
  </div>
</template>

<script>
  import Select2 from 'v-select2-component'
  import config from '@/config.js'

  export default {
    name: 'EditionSelect',
    props: ['exclude', 'edition', 'allowClear'],
    components: {
      Select2
    },
    mounted() {
      if (this.edition){
        this.$refs.select2.select2.append(
          `<option selected value="${this.edition.id}">${this.edition.sigleLegam}</option>`
        ).trigger('change')
      }
    },
    computed: {
      selectConfig(){
        return {
          placeholder: "Select an edition",
          allowClear: this.allowClear == false ? false : true,
          width: '100%',
          dropdownParent: "#select-edition",
          ajax: {
            delay: 250,
            url: config.apiUrl + '/editions/autocomplete',
            headers: config.apiHeaders,
            data: (params) => {
              return {
                query: params.term
              }
            },
            processResults: (data) => {
              return {
                results: data.map(edition => {
                  return {
                    id: edition.id,
                    text: edition.sigleLegam,
                    edition: edition
                  }
                })
              }
            }
          }
        }
      }
    },
    methods: {
      select(event){
        this.$emit('select', event.edition)
      }
    }
  }
</script>
