<template>
  <div>
    <span v-for="action in value" :key="action.id">
      <router-link v-if="action.to" :to="action.to">
        <b-button
          size="sm"
          :class="'mr-1 ' + action.class"
          variant="light"
        >
          <b-icon v-if="action.icon" :icon="action.icon" aria-hidden="true" class="mr-1"></b-icon>
          <span v-html="action.text" v-if="action.text"></span>
        </b-button>
      </router-link>

      <!--TODO: programally generated component won't fire event, so we made it with jQuery -->
      <b-button
        v-else-if="action.icon && action.text"
        size="sm"
        :class="'mr-1 ' + action.class"
        variant="light"
        :onclick="`$(document).trigger('buttonClicked', '${action.dataId.replace(/'/gi, '\\\'')}');`"
      >
        <b-icon v-if="action.icon" :icon="action.icon" aria-hidden="true" class="mr-1"></b-icon>
        <span v-html="action.text"></span>
      </b-button>

      <span v-else v-html="action.text" :class="action.class"></span>
    </span>
  </div>
</template>

<script>
  import $ from 'jquery'
  window.$ = $;

  export default {
    name: 'tdColAction',
    props: ['value']
  }
</script>
