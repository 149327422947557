<template>
  <div>
    <b-icon icon="info-circle-fill" class="pointer" @click="saveToClipboard" :id="'uuid-tooltip-' + value"></b-icon>
    <b-tooltip :target="'uuid-tooltip-' + value" placement="bottom">
      {{ value }}
    </b-tooltip>
  </div>
</template>

<script>
  export default {
    name: 'tdColUUID',
    props: ['value'],
    methods: {
      saveToClipboard(){
        const el = document.createElement('textarea')
        el.value = this.value
        document.body.appendChild(el)
        el.select()
        document.execCommand('copy')
        document.body.removeChild(el)
        this.$store.dispatch('addNotification', {
          type: 'success',
          text: `ID copied to clipboard`
        })
      }
    }
  }
</script>
