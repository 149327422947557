<template>
  <div class="manuscripts" id="select-manuscript">
    <Select2 @select="select($event)" :settings="selectConfig" ref="select2" />
  </div>
</template>

<script>
  import Select2 from 'v-select2-component'
  import config from '@/config.js'
  import $ from 'jquery'

  export default {
    name: 'ManuscriptSelect',
    props: ['exclude', 'manuscript', 'allowClear'],
    components: {
      Select2
    },
    mounted() {
      if (this.manuscript){
        this.$refs.select2.select2.append(
          `<option selected value="${this.manuscript.id}">${this.manuscript.codeRepcrit}</option>`
        ).trigger('change')
      }
    },
    computed: {
      selectConfig(){
        return {
          placeholder: "Select a manuscript",
          allowClear: this.allowClear == false ? false : true,
          width: '100%',
          templateResult: this.formatManuscript,
          dropdownParent: "#select-manuscript",
          ajax: {
            delay: 250,
            url: config.apiUrl + '/manuscripts/autocomplete',
            headers: config.apiHeaders,
            data: (params) => {
              return {
                query: params.term
              }
            },
            processResults: (data) => {
              return {
                results: data.map(manuscript => {
                  return {
                    id: manuscript.id,
                    text: manuscript.codeRepcrit,
                    manuscript: manuscript
                  }
                })
              }
            }
          }
        }
      }
    },
    methods: {
      select(event){
        this.$emit('select', event.selected ? event.manuscript : null)
      },
      formatManuscript (item) {
        let additionalData = []
        if (item.manuscript && item.manuscript.localization){
          additionalData.push(`Localization: <b>${item.manuscript.localization.display}</b>`)
        }
        if (item.manuscript && item.manuscript.dateNum){
          additionalData.push(`Date: <b>${item.manuscript.dateNum}</b>`)
        }
        let additionalText = ''
        if (additionalData.length) {
          additionalText = `<span class="select-description">${additionalData.join(', ')}</span>`
        }
        return $(`<span>${item.text}${additionalText}</span>`)
      }
    }
  }
</script>
